<template>
  <div class="register">
    <div class="register__container">
      <div>
        <div class="mb-6">
          <div class="register__title">
            Rescue Location Management
          </div>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6">
              <v-text-field
                v-model="form.username"
                :rules="usernameRules"
                :placeholder="$t('app.placeholder.username')"
                class="mr-md-6"
                required
                dense
                outlined />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <v-text-field
                v-model="form.password"
                :rules="passwordRules"
                :placeholder="$t('app.placeholder.password')"
                type="password"
                required
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6">
              <v-text-field
                v-model="form.firstName"
                :rules="firstNameRules"
                :placeholder="$t('app.placeholder.firstName')"
                class="mr-md-6"
                required
                dense
                outlined />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <v-text-field
                v-model="form.lastName"
                :rules="lastNameRules"
                :placeholder="$t('app.placeholder.lastName')"
                required
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="form.phoneNo"
                :rules="telephoneRules"
                :onkeypress="onkeypress"
                :placeholder="$t('app.placeholder.telephone')"
                type="tel"
                maxlength="10"
                required
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                :placeholder="$t('app.placeholder.email')"
                type="email"
                required
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="form.nationalId"
                :rules="nationalIdNameRules"
                :onkeypress="onkeypress"
                :placeholder="$t('app.placeholder.nationalId')"
                maxlength="13"
                required
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6">
              <v-text-field
                v-model="form.emergencyContact.name"
                :rules="eNameRules"
                :placeholder="$t('app.placeholder.emergencyName')"
                class="mr-md-6"
                required
                dense
                outlined />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <v-text-field
                v-model="form.emergencyContact.phoneNo"
                :rules="eTelephoneRules"
                :onkeypress="onkeypress"
                :placeholder="$t('app.placeholder.emergencyTelephone')"
                type="tel"
                maxlength="10"
                required
                dense
                outlined />
            </v-col>
          </v-row>
        </v-form>
        <div>
          <v-btn
            :disabled="isSubmitting"
            class="mb-4"
            color="white"
            block
            @click="registerWithGoogle()">
            <img
              src="@/assets/img/google.png"
              width="24"
              height="24"
              class="mr-4" />
            <div class="mt-1">
              {{ $t('app.btn.signUpGoogle') }}
            </div>
          </v-btn>
          <v-btn
            :disabled="isSubmitting"
            class="mb-4"
            color="white"
            block
            @click="toLineLogin()">
            <img
              src="@/assets/img/line.png"
              width="24"
              height="24"
              class="mr-4" />
            <div class="mt-1">
              {{ $t('app.btn.signUpLine') }}
            </div>
          </v-btn>
          <v-btn
            :disabled="isSubmitting"
            class="mb-4"
            color="white"
            block
            @click="registerWithUsernamePassword()">
            <v-icon class="mr-2">
              mdi-account
            </v-icon>
            <div class="mt-1">
              {{ $t('app.btn.signUp') }}
            </div>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="register__banner">
      <img
        class="w-100 h-100"
        src="@/assets/img/rescue-backgound.png" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AuthProvider from '@/resources/axios/providers/auth'
import firebase from '@/plugins/firebase'
import { lineConfig } from '@/plugins/line'

export default {
  name: 'Register',
  data () {
    return {
      valid: true,
      isSubmitting: false,
      form: {
        username: '',
        firstName: '',
        lastName: '',
        phoneNo: '',
        email: '',
        nationalId: '',
        password: '',
        emergencyContact: {
          name: '',
          phoneNo: ''
        }
      },
      onkeypress: 'return event.charCode >= 48 && event.charCode <= 57',
      usernameRules: [(v) => !!v || this.$t('app.validate.username')],
      passwordRules: [(v) => !!v || this.$t('app.validate.password')],
      firstNameRules: [(v) => !!v || this.$t('app.validate.firstName')],
      lastNameRules: [(v) => !!v || this.$t('app.validate.lastName')],
      telephoneRules: [(v) => !!v || this.$t('app.validate.telephone')],
      emailRules: [(v) => !!v || this.$t('app.validate.email')],
      nationalIdNameRules: [(v) => !!v || this.$t('app.validate.nationalId')],
      eNameRules: [(v) => !!v || this.$t('app.validate.emergencyName')],
      eTelephoneRules: [(v) => !!v || this.$t('app.validate.emergencyTelephone')]
    }
  },
  computed: {
    ...mapGetters({
      code: 'auth/code',
      info: 'auth/info',
      lineData: 'local/lineData'
    }),
    lineAccessCode () {
      return this.$route.query?.code || ''
    }
  },
  mounted () {
    if (this.lineData?.sub) {
      this.registerWithLine()
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage',
      setCode: 'auth/setCode',
      setInfo: 'auth/setInfo',
      clearInfo: 'auth/clearInfo',
      setCurrentPage: 'app/setCurrentPage',
      clearLineData: 'local/clearLineData'
    }),
    back () {
      this.$router.push({ name: 'Login' })
    },
    resetForm () {
      this.form = {
        username: '',
        firstName: '',
        lastName: '',
        phoneNo: '',
        email: '',
        nationalId: '',
        password: '',
        emergencyContact: {
          name: '',
          phoneNo: ''
        }
      }
      this.$refs.form.resetValidation()
    },
    async registerWithLine () {
      try {
        if (this.isSubmitting) { return }
        this.isSubmitting = true
        const res = await AuthProvider.signUpWithSocial({
          username: this.info.username,
          firstName: this.info.firstName,
          lastName: this.info.lastName,
          phoneNo: this.info.phoneNo,
          email: this.info.email,
          nationalId: this.info.nationalId,
          password: this.info.password,
          emergencyContact: {
            name: this.info.emergencyContact?.name,
            phoneNo: this.info.emergencyContact?.phoneNo
          },
          method: 'Social',
          uuid: this.lineData?.sub,
          invitationCode: this.code
        })
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.signUp'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.isSubmitting = false
          this.clearInfo()
          this.clearLineData()
          this.setCode('')
          this.back()
        }
      } catch (error) {
        this.showErrorMessage({
          message: error?.errors,
          type: 'warning'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
        console.log(error)
      }
    },
    async registerWithGoogle () {
      try {
        if (this.isSubmitting) { return }
        const valid = this.$refs.form.validate()
        if (valid) {
          this.isSubmitting = true
          const { user } = await firebase.auth.signInWithPopup(firebase.provider)
          if (user) {
            const res = await AuthProvider.signUpWithSocial({
              username: this.form.username,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              phoneNo: this.form.phoneNo,
              email: this.form.email,
              nationalId: this.form.nationalId,
              password: this.form.password,
              emergencyContact: {
                name: this.form.emergencyContact?.name,
                phoneNo: this.form.emergencyContact?.phoneNo
              },
              method: 'Social',
              uuid: user.uid,
              invitationCode: this.code
            })
            if (res) {
              this.showErrorMessage({
                message: this.$t('app.alert.signUp'),
                type: 'success'
              })
              setTimeout(() => {
                this.closeErrorMessage()
              }, 2000)
              this.isSubmitting = false
              this.setCode('')
              this.back()
            }
          }
        } else {
          this.showErrorMessage({
            message: this.$t('app.alert.required'),
            type: 'warning'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
        }
      } catch (error) {
        this.showErrorMessage({
          message: error?.errors,
          type: 'warning'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
        this.isSubmitting = false
        this.resetForm()
        console.log(error)
      }
    },
    toLineLogin () {
      if (this.isSubmitting) { return }
      const valid = this.$refs.form.validate()
      if (valid) {
        this.setCurrentPage(this.$route?.name)
        this.setInfo(this.form)
        window.location.href = lineConfig.uri
      } else {
        this.showErrorMessage({
          message: this.$t('app.alert.required'),
          type: 'warning'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
      }
    },
    async registerWithUsernamePassword () {
      try {
        if (this.isSubmitting) { return }
        const valid = this.$refs.form.validate()
        if (valid) {
          this.isSubmitting = true
          const res = await AuthProvider.signUpWithUsernamePassword({
            username: this.form.username,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            phoneNo: this.form.phoneNo,
            email: this.form.email,
            nationalId: this.form.nationalId,
            password: this.form.password,
            emergencyContact: {
              name: this.form.emergencyContact?.name,
              phoneNo: this.form.emergencyContact?.phoneNo
            },
            method: 'Basic',
            invitationCode: this.code
          })
          if (res) {
            this.showErrorMessage({
              message: this.$t('app.alert.signUp'),
              type: 'success'
            })
            setTimeout(() => {
              this.closeErrorMessage()
            }, 2000)
            this.isSubmitting = false
            this.setCode('')
            this.back()
          }
        } else {
          this.showErrorMessage({
            message: this.$t('app.alert.required'),
            type: 'warning'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
        }
      } catch (error) {
        this.isSubmitting = false
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .register {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #1867C0;
    /* background: linear-gradient(153.85deg, #1867C0 1.2%, rgba(31, 129, 245, 0.5625) 53.05%, #1867C0 100%); */
  }
  .register__container {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EAF0F5;
    border: 1px solid #EAF0F5;
    border-radius: 0px 18px 18px 0px;
  }
  .register__banner {
    width: 40%;
    height: 100vh;
    filter: opacity(0.5);
  }
  .register__title {
    font-size: 32px;
    font-weight: 700;
    color: var(--v-primary-base);
  }
  .register__subtitle {
    font-size: 24px;
    font-weight: 700;
    color: var(--v-primary-base);
  }

@media only screen and (max-width: 991px) {
  .register {
    flex-direction: column;
  }
  .register__container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 24px;
  }
  .register__banner {
    display: none;
  }
}
</style>
